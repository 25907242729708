<template>
  <TheLoader v-if="isLoading" />
  <H2HP2PAppealStatus
    v-else-if="
      isAppealStatusFormOpen &&
      hasAppeals &&
      actualRequest.info.payment.status !== 'success'
    "
    :appeal="appeals[0]"
    :request="actualRequest"
    @close="isAppealStatusFormOpen = false"
  />
  <H2HP2PSupport
    v-else-if="isAppealFormOpen"
    :request="actualRequest"
    @close="isAppealFormOpen = false"
    @update-appeals="getAppeals"
  />
  <operation-success
    v-else-if="actualRequest.info.payment.status === 'success'"
    :url="actualRequest.info.payment.success_url"
  />
  <operation-fail
    v-else-if="
      isFailState ||
      (isLoaderState && actualRequest.info.payment.approved_status === 'cancel')
    "
    :has-appeals="hasAppeals"
    :url="actualRequest.info.payment.fail_url"
    @open-appeal="isAppealFormOpen = true"
    @open-appeal-status="isAppealStatusFormOpen = true"
  />
  <operation-process
    v-else-if="
      isLoaderState && actualRequest.info.payment.approved_status === 'confirm'
    "
    :countdown="actualRequest.info.p2p_card_data?.valid_till"
    :has-appeals="hasAppeals"
    @open-appeal="isAppealFormOpen = true"
    @open-appeal-status="isAppealStatusFormOpen = true"
  />
  <operation-wrapper
    v-else-if="
      ['process', 'create'].includes(actualRequest?.info?.payment?.status ?? '')
    "
    :price="price"
    :type="OperationType.PAYMENT"
  >
    <H2HP2PBanks
      v-if="actualRequest.info.payment.status === 'create'"
      :request="actualRequest"
      @update-request="updateRequest"
    />
    <H2HP2PPayForm
      v-else-if="
        actualRequest.info.payment.status === 'process' &&
        actualRequest.info.p2p_card_data
      "
      :has-appeals="hasAppeals"
      :request="actualRequest"
      @update-request="updateRequest"
      @start-interval="startPaymentInterval"
      @open-appeal="isAppealFormOpen = true"
      @open-appeal-status="isAppealStatusFormOpen = true"
    />
  </operation-wrapper>
</template>

<script lang="ts" setup>
import OperationWrapper from "@/components/OperationWrapper.vue";
import { OperationType } from "@/helpers/enums";
import {
  computed,
  onBeforeMount,
  onUnmounted,
  PropType,
  ref,
  watch,
} from "vue";
import { TAppeal, TPaymentResponse } from "@/types/payment";
import { usePrice } from "@/use/usePrice";
import H2HP2PBanks from "@/components/form/H2HP2P/H2HP2PBanks.vue";
import H2HP2PPayForm from "@/components/form/H2HP2P/H2HP2PPayForm.vue";
import OperationFail from "@/components/OperationFail.vue";
import OperationSuccess from "@/components/OperationSuccess.vue";
import OperationProcess from "@/components/OperationProcess.vue";
import { useApi } from "@/plugins/api";
import H2HP2PSupport from "@/components/form/H2HP2P/H2HP2PSupport.vue";
import H2HP2PAppealStatus from "@/components/form/H2HP2P/H2HP2PAppealStatus.vue";
import TheLoader from "@/components/TheLoader.vue";

const props = defineProps({
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
});

const emit = defineEmits<{
  (e: "changeWrapper", value: boolean): void;
}>();

const actualRequest = ref<TPaymentResponse>(props.request);
const isAppealFormOpen = ref(false);
const isAppealStatusFormOpen = ref(false);
const appeals = ref<TAppeal[]>([]);
const hasAppeals = computed(() => !!appeals.value.length);
const isLoading = ref(false);

const getAppeals = async () => {
  try {
    isLoading.value = true;
    const { data } = await payment.appeals(actualRequest.value.info.tx);
    appeals.value = data;
    isAppealStatusFormOpen.value = data.length !== 0;
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

watch(
  isAppealFormOpen,
  (value) => {
    emit("changeWrapper", value);
  },
  { immediate: true }
);

const isLoaderState = computed(() =>
  ["process", "queue", "waiting", "unknown", "hold"].includes(
    actualRequest.value?.info?.payment?.status ?? ""
  )
);

const isFailState = computed(() =>
  [
    "error",
    "reversal",
    "antifraud_error",
    "reversal_timeout",
    "provider_timeout",
  ].includes(actualRequest.value?.info?.payment?.status ?? "")
);

const updateRequest = (request: TPaymentResponse) => {
  actualRequest.value = request;
};

const { payment } = useApi();
let interval = 0;

const getPaymentInfo = async () => {
  try {
    const { data } = await payment.info(actualRequest.value.info.tx);
    updateRequest(data);

    if (!isLoaderState.value) {
      clearInterval(interval);
    }
  } catch (e) {
    console.error(e);
  }
};

const startPaymentInterval = () => {
  if (!interval) {
    interval = setInterval(getPaymentInfo, 20000);
  }
};

onBeforeMount(getAppeals);

onUnmounted(() => {
  clearInterval(interval);
});

const price = usePrice(
  actualRequest.value.info?.payment?.amount ?? 0,
  actualRequest.value?.info?.payment?.currency?.char_code
);
</script>
